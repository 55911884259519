import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Material } from '@domain/models/material.model';
import { MaterialGroup } from '@domain/models/material-group.model';
import { ProjectMaterial } from '@domain/models/project-material.model';
import { Project } from '@domain/models/project.model';
import { ProjectService } from '@shared/services/project.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-material',
  templateUrl: './material.component.html',
  styleUrls: ['./material.component.scss']
})
export class MaterialComponent implements OnInit, OnDestroy {
  public project = new Project({});
  public materials: Material[] = [];
  public materialGroups: MaterialGroup[] = [];
  public projectMaterials: ProjectMaterial[] = [];
  public disabled = true;
  public arentMaterialsEnabled = false;

  private subscriptionProjectLoaded: Subscription;

  public constructor(private projectService: ProjectService) {
    this.arentMaterialsEnabled = !!environment.features.arent_materials;

    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public async ngOnInit(): Promise<void> {
    if (!this.arentMaterialsEnabled) {
      await this.getMaterials();
    }

    this.project = this.projectService.getProject();

    // Load on project
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe(async (project: Project) => {
          this.project = project;
          await this.getProjectMaterials();
        }
    );

    await this.getProjectMaterials();
  }

  public async getProjectMaterials(): Promise<void> {
    if (this.arentMaterialsEnabled) {
      this.materialGroups = await MaterialGroup.query.toArray();
    }

    this.projectMaterials = await ProjectMaterial.query
        .where('project_id')
        .equals(this.project.id)
        .toArray();
  }

  public async onMaterialGroupChange(materialGroup: MaterialGroup): Promise<void> {
    await this.getMaterials(materialGroup);
  }

  public async ngOnDestroy(): Promise<void> {
    await this.projectService.saveProjectMaterials(this.projectMaterials);
    this.projectService.setProjectUpdated();
    await this.projectService.saveProject();

    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public getAmount(material: Material): number {
    const item = this.getMaterialItem(material);
    return item ? item.amount : 0;
  }

  public changeAmount(item: Material|ProjectMaterial|any, amount: number): void {
    if (!amount || amount < 0) {
      amount = 0;
    }

    if (item && !item.amount && +item.amount !== 0 && this.getMaterialItem(item)) {
      item = this.getMaterialItem(item);
      item.amount = amount;

      this.projectService.updateMaterial(item);
    } else {
      const newProjectMaterial = new ProjectMaterial({
        project_id: this.project.id,
        material_id: item.material_id || item.id,
        amount: amount
      });

      this.projectMaterials.push(newProjectMaterial);
      this.projectService.updateMaterial(newProjectMaterial);
    }
  }

  private async getMaterials(materialGroup?: MaterialGroup): Promise<void> {
    let result;
    if (materialGroup) {
      result = await Material.query
          .where('material_group_id')
          .equals(materialGroup.id)
          .toArray();
    } else {
      result = await Material.query.toArray();
    }

    // Sort the materials alphabetically
    this.materials = result.sort((a: any, b: any) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();

      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  private getMaterialItem(material: Material): ProjectMaterial {
    return this.projectMaterials.find((value: ProjectMaterial) => {
      return value.material_id === material.id || false;
    });
  }
}
