import { DomainModel } from '@domain/domain.model';

export class HouseType extends DomainModel {
  // Configuration
  public entity = 'house-type';
  public table = 'house_types';
  public schema = '++id';
  public sync = true;

  // Fields
  public id: string;
  public name: string;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
