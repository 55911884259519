import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineTableOptions } from '@shared/controls/table/online-table.component';
import * as moment from '@node_modules/moment';

@Component({
  selector: 'app-bulk-prices-list',
  templateUrl: './bulk-prices-list.component.html',
  styleUrls: ['./bulk-prices-list.component.scss']
})
export class ManageBulkPricesListComponent implements OnInit {
  public tableOptions: OnlineTableOptions;

  public constructor(private router: Router) {}

  public async ngOnInit(): Promise<void> {
    this.tableOptions = new OnlineTableOptions({
      columns: [
        { title: 'Startdatum', name: 'start_date', filter: { global: true } },
        { title: 'Einddatum', name: 'end_date', filter: { global: true } },
        { title: 'Min. km', name: 'distance_min', filter: { global: true } },
        { title: 'Max. km', name: 'distance_max', filter: { global: true } },
        { title: 'Min. m3', name: 'volume_min', filter: { global: false } },
        { title: 'Max. m3', name: 'volume_max', filter: { global: false } },
        { title: 'Prijs excl.', name: 'price', filter: { global: false } },
      ],
      endpoint: '/bulk-prices',
      search: true,
      withDelete: true,
      withFilter: false,
      sort: { column: 'distance_min' },
      rowDataTransformer: (rows) => {
        for (const row of rows) {
          row.start_date = moment(row.start_date).format('DD-MM-YYYY');
          row.end_date = moment(row.end_date).format('DD-MM-YYYY');
        }

        return rows;
      },
    });
  }

  public onRowClick(row: any) {
    this.router.navigateByUrl(`/admin/manage/bulk-prices(popup:admin/manage/bulk-prices/${row.id})`);
  }

  public addNewItem(): void {
    this.router.navigateByUrl('/admin/manage/bulk-prices(popup:admin/manage/bulk-prices/add)');
  }
}
