import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { StepsModule, CheckboxModule, InputSwitchModule, CalendarModule } from 'primeng/primeng';
import { InputMaskModule, DialogModule, AutoCompleteModule } from 'primeng/primeng';
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { SharedModule } from '@shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { InventoryFlowComponent } from '@features/inventory/inventory-flow.component';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { InventoryAddressDetailComponent } from '@features/inventory/address/address-detail.component';
import { WorkassignmentAddressDetailComponent } from '@features/inventory/work-assignment/workassignment-address-detail/workassignment-address-detail.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { InventoryClientSearchComponent } from '@features/inventory/client/client-search.component';
import { DataService } from '@shared/services/data.service';
import { InventoryProjectComponent } from '@features/inventory/project/project-detail.component';
import { InventoryHeaderComponent } from '@features/inventory/inventory/header/inventory-header.component';
import { InventoryItemComponent } from '@features/inventory/inventory/item/inventory-item.component';
import { InventoryAddItemComponent } from '@features/inventory/inventory/add-item/inventory-add-item.component';
import { InventoryItemDetailsComponent } from '@features/inventory/inventory/details/inventory-item-details.component';
import { InventoryQuotationComponent } from '@features/inventory/quotation/quotation.component';
import { InventoryAddComponent } from '@features/inventory/inventory/add/inventory-add.component';
import { InventoryStaticComponent } from '@features/inventory/inventory/static/inventory-static.component';
import { InventoryWorkAssignmentListComponent } from '@features/inventory/work-assignment/work-assignment-list.component';
import { InventoryWorkAssignmentDetailComponent } from '@features/inventory/work-assignment/work-assignment-detail.component';
import { InventoryWorkAssignmentItemComponent } from '@features/inventory/work-assignment/work-assignment-item.component';
import { MaterialComponent } from '@features/inventory/material/material.component';
import { MaterialHeaderComponent } from '@features/inventory/material/header/material-header.component';
import { OptionsDetailItemComponent } from './options/options-detail-item/options-detail-item.component';
import { InventoryOverviewComponent } from './overview/overview.component';
import { InventoryWorkAssignmentDropdownItemComponent } from '@features/inventory/work-assignment/work-assignment-dropdown-item.component';
import { InventoryAddressSortComponent } from '@features/inventory/address/address-sort/address-sort.component';
import { InventoryPicturesComponent } from '@features/inventory/pictures/pictures.component';
import { InventoryPictureItemComponent } from '@features/inventory/pictures/picture-item/picture-item.component';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';
import { EventDetailComponent } from '@features/inventory/event/event-detail.component';
import { EventListComponent } from '@features/inventory/event/event-list.component';

@NgModule({
  providers: [
    DataService
  ],
  declarations: [
    InventoryFlowComponent,
    InventoryProjectComponent,
    InventoryClientComponent,
    InventoryContactListComponent,
    InventoryContactsDetailComponent,
    InventoryAddressListComponent,
    InventoryAddressDetailComponent,
    InventoryContactListComponent,
    InventoryOptionsDetailComponent,
    InventoryBoardComponent,
    InventoryHeaderComponent,
    InventoryItemComponent,
    InventoryAddItemComponent,
    InventoryItemDetailsComponent,
    InventoryClientSearchComponent,
    InventoryQuotationComponent,
    InventoryStaticComponent,
    InventoryAddComponent,
    InventoryWorkAssignmentListComponent,
    InventoryWorkAssignmentDetailComponent,
    InventoryWorkAssignmentItemComponent,
    InventoryWorkAssignmentDropdownItemComponent,
    WorkassignmentAddressDetailComponent,
    OptionsDetailItemComponent,
    MaterialComponent,
    MaterialHeaderComponent,
    InventoryOverviewComponent,
    InventoryAddressSortComponent,
    InventoryPicturesComponent,
    InventoryPictureItemComponent,
    InventoryPictureItemDetailComponent,
    EventDetailComponent,
    EventListComponent,
  ],
  imports: [
    Ng2ImgMaxModule,
    BrowserModule,
    FormsModule,
    StepsModule,
    CheckboxModule,
    CalendarModule,
    InputMaskModule,
    CurrencyMaskModule,
    EmendisBlueprintModule,
    SharedModule,
    RouterModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputSwitchModule,
    DragDropModule
  ],
  exports: [
    InventoryFlowComponent,
  ]
})

export class InventoryModule {
}
