import { Routes } from '@angular/router';
import { AuthGuard } from '@blueprint/auth/auth.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ClientGuard } from '@shared/guards/client.guard';
import { AddressGuard } from '@shared/guards/address.guard';
import { ContactGuard } from '@shared/guards/contact.guard';
import { ContactListGuard } from '@shared/guards/contact-list.guard';
import { OptionsGuard } from '@shared/guards/options.guard';
import { InventoryGuard } from '@shared/guards/inventory.guard';
import { QuotationGuard } from '@shared/guards/quotation.guard';
import { BootstrapGuard } from '@shared/guards/bootstrap.guard';
import { LoginComponent } from '@features/login/login.component';
import { UserOverviewComponent } from '@features/user/user-overview.component';
import { UserDetailComponent } from '@features/user/user-detail.component';
import { LoginLayoutComponent } from '@features/gui/layouts/loginLayout.component';
import { FooterNavigationLayoutComponent } from '@features/gui/layouts/footerNavigationLayout.component';
import { ProjectOverviewComponent } from '@features/project/project-overview.component';
import { InventoryFlowComponent } from '@features/inventory/inventory-flow.component';
import { InventoryAddressDetailComponent } from '@features/inventory/address/address-detail.component';
import { InventoryOptionsDetailComponent } from '@features/inventory/options/options-detail.component';
import { InventoryQuotationComponent } from '@features/inventory/quotation/quotation.component';
import { InventoryWorkAssignmentListComponent } from '@features/inventory/work-assignment/work-assignment-list.component';
import { InventoryWorkAssignmentDetailComponent } from '@features/inventory/work-assignment/work-assignment-detail.component';
import { InventoryBoardComponent } from '@features/inventory/inventory/inventory-board.component';
import { PopupLayoutComponent } from '@features/gui/layouts/popupLayout.component';
import { InventoryContactsDetailComponent } from '@features/inventory/contact/contact-detail.component';
import { InventoryClientComponent } from '@features/inventory/client/client.component';
import { InventoryAddressListComponent } from '@features/inventory/address/address-list.component';
import { InventoryClientSearchComponent } from '@features/inventory/client/client-search.component';
import { InventoryAddComponent } from '@features/inventory/inventory/add/inventory-add.component';
import { InventoryContactListComponent } from '@features/inventory/contact/contact-list.component';
import { SettingsOverviewComponent } from '@features/settings/settings-overview.component';
import { MaterialComponent } from '@features/inventory/material/material.component';
import { InventoryOverviewComponent } from '@features/inventory/overview/overview.component';
import { InventoryAddressSortComponent } from '@features/inventory/address/address-sort/address-sort.component';
import { InventoryPicturesComponent } from '@features/inventory/pictures/pictures.component';
import { InventoryPictureItemDetailComponent } from '@features/inventory/pictures/picture-item-detail/picture-item-detail.component';
import { ManageContactsListComponent } from '@features/manage/contacts/contacts-list/contacts-list.component';
import { ManageAddressesListComponent } from '@features/manage/addresses/addresses-list/addresses-list.component';
import { ManageComponent } from '@features/manage/manage.component';
import { ManageAddressesDetailComponent } from '@features/manage/addresses/addresses-detail/addresses-detail.component';
import { ManageContactsDetailComponent } from '@features/manage/contacts/contacts-detail/contacts-detail.component';
import { QuotationSignComponent } from '@features/quotationSign/quotation-sign.component';
import { ManageDefaultInventoriesListComponent } from '@features/manage/default-inventories/default-inventories-list/default-inventories-list.component';
import { ManageDefaultItemsListComponent } from '@features/manage/default-items/default-items-list/default-items-list.component';
import { ManageDefaultInventoriesDetailComponent } from '@features/manage/default-inventories/default-inventories-detail/default-inventories-detail.component';
import { ManageDefaultItemsDetailComponent } from '@features/manage/default-items/default-items-detail/default-items-detail.component';
import { ManageMaterialsListComponent } from '@features/manage/materials/materials-list/materials-list.component';
import { ManageBulkPricesListComponent } from '@features/manage/bulk-prices/bulk-prices-list/bulk-prices-list.component';
import { ManageMaterialsDetailComponent } from '@features/manage/materials/materials-detail/materials-detail.component';
import { ManageBulkPricesDetailComponent } from '@features/manage/bulk-prices/bulk-prices-detail/bulk-prices-detail.component';
import { ManageOptionsDetailComponent } from '@features/manage/options/options-detail/options-detail.component';
import { ManageActivitiesDetailComponent } from '@features/manage/activities/activities-detail/activities-detail.component';
import { ManageActivitiesListComponent } from '@features/manage/activities/activities-list/activities-list.component';
import { ManageOptionsListComponent } from '@features/manage/options/options-list/options-list.component';
import { ManageRelationGroupsDetailComponent } from '@features/manage/relation-groups/relation-groups-detail/relation-groups-detail.component';
import { ManageRelationGroupsListComponent } from '@features/manage/relation-groups/relation-groups-list/relation-groups-list.component';
import { EventDetailComponent } from '@features/inventory/event/event-detail.component';
import { EventListComponent } from '@features/inventory/event/event-list.component';

export const ROUTES: Routes = [
  // Admin section
  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    component: FooterNavigationLayoutComponent,
    children: [
      // Main redirect
      { path: '', redirectTo: 'projects', pathMatch: 'full' },

      // App views
      { path: 'settings', component: SettingsOverviewComponent },
      { path: 'projects', component: ProjectOverviewComponent },
      {
        path: 'users',
        component: UserOverviewComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'project/:project',
        component: InventoryFlowComponent,
        children: [
          {
            path: 'client',
            component: InventoryClientComponent,
            canDeactivate: [ClientGuard]
          },
          {
            path: 'address',
            component: InventoryAddressListComponent,
            canDeactivate: [AddressGuard]
          },
          {
            path: 'contact',
            component: InventoryContactListComponent,
            canDeactivate: [ContactGuard]
          },
          {
            path: 'options',
            component: InventoryOptionsDetailComponent,
            canActivate: [ContactListGuard],
            canDeactivate: [OptionsGuard]
          },
          {
            path: 'inventory',
            component: InventoryBoardComponent,
            canActivate: [ContactListGuard],
            canDeactivate: [InventoryGuard]
          },
          {
            path: 'material',
            component: MaterialComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'overview',
            component: InventoryOverviewComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'quotation',
            component: InventoryQuotationComponent,
            canActivate: [QuotationGuard, ContactListGuard]
          },
          {
            path: 'pictures',
            component: InventoryPicturesComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'work-assignment',
            component: InventoryWorkAssignmentListComponent,
            canActivate: [ContactListGuard]
          },
          {
            path: 'work-assignment/:id',
            component: InventoryWorkAssignmentDetailComponent,
            canActivate: [ContactListGuard]
          }
        ]
      },
      {
        path: 'manage',
        canActivate: [],
        children: [
          {
            path: 'addresses',
            component: ManageAddressesListComponent
          },
          {
            path: 'contacts',
            component: ManageContactsListComponent
          },
          {
            path: 'materials',
            component: ManageMaterialsListComponent
          },
          {
            path: 'options',
            component: ManageOptionsListComponent
          },
          {
            path: 'activities',
            component: ManageActivitiesListComponent
          },
          {
            path: 'relation-groups',
            component: ManageRelationGroupsListComponent
          },
          {
            path: 'default-inventories',
            component: ManageDefaultInventoriesListComponent
          },
          {
            path: 'default-items',
            component: ManageDefaultItemsListComponent
          },
          {
            path: 'bulk-prices',
            component: ManageBulkPricesListComponent
          },
          {
            path: '',
            component: ManageComponent
          }
        ]
      }
    ]
  },

  {
    path: 'admin',
    canActivate: [AuthGuard, BootstrapGuard],
    canActivateChild: [AuthGuard],
    component: PopupLayoutComponent,
    outlet: 'popup',
    children: [
      {
        path: 'project/address/add',
        component: InventoryAddressDetailComponent
      },
      {
        path: 'project/address/:id',
        component: InventoryAddressDetailComponent
      },
      {
        path: 'manage/addresses/:id',
        component: ManageAddressesDetailComponent
      },
      {
        path: 'manage/contacts/:id',
        component: ManageContactsDetailComponent
      },
      {
        path: 'manage/materials/:id',
        component: ManageMaterialsDetailComponent
      },
      {
        path: 'manage/options/:id',
        component: ManageOptionsDetailComponent
      },
      {
        path: 'manage/activities/:id',
        component: ManageActivitiesDetailComponent
      },
      {
        path: 'manage/relation-groups/:id',
        component: ManageRelationGroupsDetailComponent
      },
      {
        path: 'manage/default-inventories/:id',
        component: ManageDefaultInventoriesDetailComponent
      },
      {
        path: 'manage/default-items/:id',
        component: ManageDefaultItemsDetailComponent
      },
      {
        path: 'manage/bulk-prices/:id',
        component: ManageBulkPricesDetailComponent
      },
      {
        path: 'project/address-sort',
        component: InventoryAddressSortComponent
      },
      {
        path: 'project/contact/add',
        component: InventoryContactsDetailComponent
      },
      {
        path: 'project/contact/:id',
        component: InventoryContactsDetailComponent
      },
      {
        path: 'project/client-search',
        component: InventoryClientSearchComponent
      },
      {
        path: 'project/inventory/add',
        component: InventoryAddComponent
      },
      {
        path: 'project/pictures/add',
        component: InventoryPictureItemDetailComponent
      },
      {
        path: 'project/pictures/:id',
        component: InventoryPictureItemDetailComponent
      },
      {
        path: 'user/:id',
        component: UserDetailComponent,
        canActivate: [UserGuard]
      },
      {
        path: 'project/client/events',
        component: EventListComponent
      },
      {
        path: 'project/client/events/add',
        component: EventDetailComponent
      },
    ]
  },
  {
    path: '',
    redirectTo: 'admin/projects',
    pathMatch: 'full'
  },
  {
    path: 'quotation/sign/:id/:project_id',
    component: QuotationSignComponent
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [{ path: 'login', component: LoginComponent }]
  },

  // Handle all other routes
  { path: '**', redirectTo: 'admin' }
];
