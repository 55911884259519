import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ProjectService } from '@shared/services/project.service';

@Injectable()
export class ContactListGuard implements CanActivate {

  public constructor(private projectService: ProjectService) {}

  /**
   * Check if project-client has any contact persons
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Promise<boolean>}
   */
  async canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ) {

    if (!await this.projectService.hasAddresses(route.parent.params.project)) {
      toastr.error('Het project moet minimaal één laad- en losadres hebben.');
      return false;
    }

    if (!await this.projectService.hasContacts(route.parent.params.project)) {
      toastr.error('Het project moet minimaal één contactpersoon hebben.');
      return false;
    }

    return true;
  }
}
