import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Quotation } from '@domain/models/quotation.model';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ProjectService } from '@shared/services/project.service';
import { DataService } from '@shared/services/data.service';
import * as toastr from 'toastr';
import * as moment from 'moment';
import { SignatureComponent } from '@root/src/app/shared/controls/signature/signature.component';
import { QuotationSignService } from '@root/src/app/shared/services/quotation-sign.service';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
registerLocaleData(localeNl, 'nl');

@Component({
  selector: 'app-project-quotation-sign',
  templateUrl: 'quotation-sign.component.html',
  styleUrls: ['./quotation-sign.component.scss']
})
export class QuotationSignComponent implements OnInit, OnDestroy {
  @ViewChild('clientSignature')
  public clientSignature: SignatureComponent;

  @ViewChild('valuatorSignature')
  public valuatorSignature: SignatureComponent;

  public quotation: Quotation;
  public disabled: boolean;
  public projectId: string;
  public quotationSignId: string;
  public today: string;
  public loading: boolean;
  public error: boolean;
  public submitted: boolean;
  public errorMessage: string;
  public project: any;

  private subscriptionProjectLoaded: Subscription;

  constructor(private syncService: SynchronisationService,
              private projectService: ProjectService,
              private dataService: DataService,
              private route: ActivatedRoute,
              private quotationService: QuotationSignService) {
    this.quotation = null;
    this.projectId = null;
    this.quotationSignId = null;
    this.loading = true;
    this.error = false;
    this.submitted = false;
    this.today = null;

    this.route.params.subscribe((params) => {
      this.projectId = params['project_id'];
      this.quotationSignId = params['id'];
    });
  }

  public ngOnInit(): void {
    this.getQuotations();
  }

  public getQuotations(): void {
    this.subscriptionProjectLoaded = this.quotationService.getQuotation(this.quotationSignId, this.projectId).subscribe( response => {
      if (response.project) {
        this.project = response.project;
      }

      if (response.quotation) {
        response.quotation.signature_client_date = response.quotation.signature_client_date ? new Date(response.quotation.signature_client_date) : null;
        response.quotation.signature_valuator_date = response.quotation.signature_valuator_date ? new Date(response.quotation.signature_valuator_date) : null;

        this.quotation = response.quotation;
      }

      this.disabled = true;

      if (response.status === 'error') {
        this.error = true;
        this.errorMessage = response.message;
      }

      this.loading = false;
    }, () => {
      toastr.error('Er ging iets verkeerd', 'Foutmelding');
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
  }

  public async submitQuotation(): Promise<void> {
    this.quotation.signature_client_date = this.quotation.signature_client_date ? moment(this.quotation.signature_client_date).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    this.quotation.signature_valuator_date = this.quotation.signature_valuator_date ? moment(this.quotation.signature_valuator_date).format('YYYY-MM-DD HH:mm:ss') : moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

    this.quotationService.signQuotation({
      quotation_sign_id: this.quotationSignId,
      quotation: this.quotation
    }).subscribe(() => {
      this.submitted = true;
      toastr.success('Offerte succesvol ondertekend', 'Synchroniseren');
    }, () => {
      toastr.error('Er ging iets verkeerd', 'Foutmelding');
    });
  }

  public showClientSignatureForm(): void {
    this.today = this.getMoment();

    this.clientSignature.showForm();
  }

  private getMoment(): string {
    return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  }
}
