import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByProperty'
})
export class SortByPropertyPipe implements PipeTransform {
  transform(values: any[], property: string, sortOrder: string = 'asc'): any {
    return values.sort((a, b) => {
      let properties = [];

      if (property.includes('.')) {
        properties = property.split('.');
      }

      if (properties && properties.length > 0) {
        for (let i = 0; i < properties.length; i++) {
          a = a[properties[i]];
          b = b[properties[i]];
        }
      } else {
        a = a[property];
        b = b[property];
      }

      if (!a || !b) {
        return 1;
      }

      return sortOrder === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
    });
  }
}
