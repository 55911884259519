import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { User } from '@domain/models/user.model';
import { SignatureComponent } from '@shared/controls/signature/signature.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  @ViewChild('signature') signature: SignatureComponent;

  public form: any;
  public user: User = new User({});
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public roleList: SelectItem[] = [];

  private signatureEdited = false;

  public constructor(private api: ApiServiceWithLoaderService,
                     private route: ActivatedRoute,
                     private router: Router,
                     private formBuilder: FormBuilder,
                     private confirmationService: ConfirmationService) {
  }

  public ngOnInit(): void {
    this.loading = true;
    this.initForm();

    // Get id of address to edit by route params
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.isAdd = id === 'add';
      if (!this.isAdd) {
        this.api.get('/user/' + id).subscribe((result: any) => {
          this.user = result.data;
          this.initForm();
          this.loading = false;
        });
      } else {
        this.loading = false;
        this.initForm();
      }
    });

    // Add role (TODO: hardcoded for now, because Blueprint needs to be changed for this)
    this.roleList = [];
    this.roleList.push({ label: 'Selecteer rol', value: null });
    this.roleList.push({ label: 'Administrator', value: 2 });
    this.roleList.push({ label: 'Gebruiker', value: 3 });
    this.roleList.push({ label: 'Voorman', value: 4 });
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      id: this.formBuilder.control(this.user.id || null),
      name: this.formBuilder.control(this.user.name, Validators.required),
      email: this.formBuilder.control(this.user.email, Validators.email),
      password: this.formBuilder.control(this.user.password, Validators.minLength(8)),
      password_confirmation: this.formBuilder.control(this.user.password_confirmation, Validators.minLength(8)),
      role_id: this.formBuilder.control(this.user.role_id, Validators.required),
      is_accountmanager: this.formBuilder.control(this.user.is_accountmanager, Validators.required),
    });

    if (this.isAdd) {
      this.form.controls.password.setValidators([Validators.required, Validators.minLength(8)]);
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      this.showErrors = true;
      return;
    }

    // Check password match
    if (this.form.value.password !== this.form.value.password_confirmation) {
      this.showErrors = true;
      toastr.warning('Wachtwoorden komen niet overeen', 'Gebruikers');
      return;
    }

    this.showErrors = false;
    this.loading = true;

    const userData = { ...this.form.value };
    if (!userData.password || userData.password.length === 0) {
      delete userData.password;
      delete userData.password_confirmation;
    }

    if (this.signatureEdited) {
      userData.signature_image = this.user.signature_image;
    }

    let request;
    if (this.isAdd) {
      request = this.api.post('/user', userData);
    } else {
      request = this.api.patch('/user/' + userData.id, userData);
    }

    request.subscribe(_ => {
        toastr.success('Gebruiker succesvol opgeslagen', 'Gebruikers');

        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/users');
        });
      }, (error: any) => {
        this.loading = false;
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Gebruikers');
        } else {
          toastr.error('Fout bij het bijwerken van de gebruikers', 'Gebruikers');
        }
      }
    );
  }

  public onDelete(): void {
    this.confirmationService.confirm({
      message: 'Wilt u de geselecteerde gebuiker verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: _ => {
        this.api.delete('/user/' + this.user.id).subscribe(_ => {
          toastr.success('Gebruiker succesvol verwijderd', 'Gebruikers');
          this.router.navigateByUrl('/').then(() => {
            // HACK Use double navigation to force reload..
            this.router.navigateByUrl('/admin/users');
          });
        }, () => {
          toastr.error('Fout bij verwijderen gebruiker', 'Gebruikers');
        });
      }
    });
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/users');
  }

  public showSignatureForm(): void {
    this.signatureEdited = true;
    this.signature.showForm();
  }

  public deleteSignature(): void {
    this.signatureEdited = true;
    this.user.signature_image = null;
  }
}
