import { DomainModel } from '@domain/domain.model';
import { RelationGroup } from '@domain/models/relation-group.model';

export class Specialty extends DomainModel {
  // Configuration
  public entity = 'specialty';
  public table = 'specialties';
  public schema = '++id, remote_id, project_type';
  public sync = true;

  // Fields
  public relation_group_id: string;
  public name: string;
  public exact_code: string;
  public cost_rate: number;
  public vat_rate: number;
  public has_hours: boolean;
  public has_remarks: boolean;
  public has_date: boolean;
  public sends_notification: boolean;
  public deleted_at: Date|null;

  public relation_group: RelationGroup;

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
