import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmendisBlueprintModule } from '@blueprint/emendis-blueprint.module';
import { DataService } from '@shared/services/data.service';
import { ManageComponent } from './manage.component';
import { ManageAddressesListComponent } from './addresses/addresses-list/addresses-list.component';
import { ManageAddressesDetailComponent } from './addresses/addresses-detail/addresses-detail.component';
import { ManageContactsListComponent } from './contacts/contacts-list/contacts-list.component';
import { ManageContactsDetailComponent } from './contacts/contacts-detail/contacts-detail.component';
import { ManageDefaultInventoriesListComponent } from '@features/manage/default-inventories/default-inventories-list/default-inventories-list.component';
import { ManageDefaultInventoriesDetailComponent } from '@features/manage/default-inventories/default-inventories-detail/default-inventories-detail.component';
import { ManageDefaultItemsDetailComponent } from '@features/manage/default-items/default-items-detail/default-items-detail.component';
import { ManageDefaultItemsListComponent } from '@features/manage/default-items/default-items-list/default-items-list.component';
import { MultiSelectModule } from '@node_modules/primeng/components/multiselect/multiselect';
import { ManageBulkPricesListComponent } from '@features/manage/bulk-prices/bulk-prices-list/bulk-prices-list.component';
import { ManageBulkPricesDetailComponent } from '@features/manage/bulk-prices/bulk-prices-detail/bulk-prices-detail.component';
import { ManageMaterialsListComponent } from '@features/manage/materials/materials-list/materials-list.component';
import { ManageMaterialsDetailComponent } from '@features/manage/materials/materials-detail/materials-detail.component';
import { ManageOptionsListComponent } from '@features/manage/options/options-list/options-list.component';
import { ManageOptionsDetailComponent } from '@features/manage/options/options-detail/options-detail.component';
import { CheckboxModule } from '@node_modules/primeng/components/checkbox/checkbox';
import { ManageActivitiesListComponent } from '@features/manage/activities/activities-list/activities-list.component';
import { ManageActivitiesDetailComponent } from '@features/manage/activities/activities-detail/activities-detail.component';
import { ManageRelationGroupsDetailComponent } from '@features/manage/relation-groups/relation-groups-detail/relation-groups-detail.component';
import { ManageRelationGroupsListComponent } from '@features/manage/relation-groups/relation-groups-list/relation-groups-list.component';

@NgModule({
  providers: [
    DataService
  ],
  declarations: [
    ManageComponent,
    ManageAddressesListComponent,
    ManageAddressesDetailComponent,
    ManageContactsListComponent,
    ManageContactsDetailComponent,
    ManageMaterialsListComponent,
    ManageMaterialsDetailComponent,
    ManageOptionsListComponent,
    ManageOptionsDetailComponent,
    ManageActivitiesListComponent,
    ManageRelationGroupsListComponent,
    ManageActivitiesDetailComponent,
    ManageRelationGroupsDetailComponent,
    ManageDefaultInventoriesListComponent,
    ManageDefaultInventoriesDetailComponent,
    ManageDefaultItemsListComponent,
    ManageDefaultItemsDetailComponent,
    ManageBulkPricesListComponent,
    ManageBulkPricesDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    EmendisBlueprintModule,
    MultiSelectModule,
    CheckboxModule,
  ],
})
export class ManageModule {
}
