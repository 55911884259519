import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { ProjectService } from '@shared/services/project.service';

@Component({
  selector: 'app-inventory-header',
  templateUrl: 'inventory-header.component.html',
  styleUrls: ['./inventory-header.component.scss']
})
export class InventoryHeaderComponent implements OnInit, OnChanges {
  @Input('inventory') inventory;
  @Input('project') project;
  @Input('inventories') inventories;
  @Input('volumeTotal') volumeTotal;
  @Input('assemblyTotal') assemblyTotal;
  @Input('packingTotal') packingTotal;
  @Input('meterboxTotal') meterboxTotal;
  @Input('disabled') disabled;

  @Output('inventoryChange') inventoryChange = new EventEmitter;

  public floor;
  public inventoryList: SelectItem[] = [];

  constructor(private router: Router,
              private projectService: ProjectService,
              private confirmationService: ConfirmationService) {
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
  }

  public ngOnChanges(): void {
    this.loadInventories();
  }

  /**
   * Check if the given input is a number; otherwise return 0
   * @param input: any
   */
  public checkIfNumber(input: any): number {
    if (!Number(input)) {
      try {
        /** Parse string to float, and format to 2 decimals */
        let result = parseFloat(input);
        return result = Math.round(result * 100) / 100;
      } catch {
        return 0;
      }
    }

    return Number(input) || 0;
  }

  /**
   * When new inventory is selected
   */
  public onInventoryChange(): void {
    this.inventoryChange.emit(this.inventory);
  }

  public onAddClick(): void {
    this.router.navigateByUrl('/admin/project/' + this.project.id + '/inventory(popup:admin/project/inventory/add)');
  }

  /**
   * Delete selected inventory
   */
  public onDeleteClick(): void {
    this.confirmationService.confirm({
      message: 'Wilt u deze inventarisatie verwijderen?',
      header: 'Bevestiging',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.projectService.deleteInventory(this.inventory.id);
      }
    });
  }

  public getTotalOfProduct(name: string): number {
    let total = 0;

    for (const inventory of this.inventories) {
      if (inventory.items) {
        for (const item of inventory.items) {
          if (item.name.toLowerCase() === name.toLowerCase()) {
            if (item.amount) {
              total += +item.amount;
            }
          }
        }
      }
    }

    return total;
  }

  /**
   * Load inventories for dropdown
   * Don't push zakelijk inventories when project is particulier
   */
  private loadInventories(): void {
    this.inventoryList = [];

    if (this.inventories) {
      this.inventories.forEach((inventory) => {
        if (inventory.name !== 'School' || this.project.projectType === 'Zakelijk') {
          this.inventoryList.push({ label: inventory.name, value: inventory });
        }
      });

      /** List of the correct order of inventories */
      const correctOrder: string[] = [
        'Woonkamer / Eetkamer',
        'Keuken',
        'Hal / Toilet',
        'Slaapkamer 1',
        'Slaapkamer 2',
        'Slaapkamer 3',
        'Slaapkamer 4',
        'Slaapkamer 5',
        'Studeerkamer',
        'Badkamer',
        'Zolder',
        'Berging, tuin'
      ];

      /** Sort the inventories based on list */
      this.inventoryList = this.inventoryList.sort((a: any, b: any) => {
        return correctOrder.indexOf(a.label) < correctOrder.indexOf(b.label) ? -1 : 1;
      });
    }
  }
}
