import { DomainModel } from '@domain/domain.model';
import { DefaultItem } from '@domain/models/default-item.model';

export class DefaultInventory extends DomainModel {

  // Configuration
  public entity = 'default-inventory';
  public table = 'default_inventories';
  public schema = '++id, remote_id';
  public sync = true;

  // Fields
  public name: string;
  public type: string;
  public type_label?: string;
  public default_items?: DefaultItem[];

  // Constructor
  constructor(attributes) {
    super(attributes);
  }
}
