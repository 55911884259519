import { DomainModel } from '@domain/domain.model';
import { Client } from '@domain/models/client.model';
import { Address } from '@domain/models/address.model';
import { Activity } from '@domain/models/activity.model';
import { Specialty } from '@domain/models/specialty.model';
import { ProjectActivity } from '@domain/models/project-activity.model';
import { ProjectSpecialty } from '@domain/models/project-specialty.model';
import { Inventory } from '@domain/models/inventory.model';
import { Quotation } from '@domain/models/quotation.model';
import { SelectItem } from 'primeng/api';
import { User } from '@domain/models/user.model';
import { ProjectMaterial } from './project-material.model';
import { Picture } from './picture.model';
import * as uuid from 'uuid/v4';
import * as moment from 'moment';
import { Event } from '@domain/models/event.model';

export class Project extends DomainModel {
  // Configuration
  public entity = 'project';

  public table = 'projects';
  public schema = 'id';
  public sync = true;
  public id: string;

  // Fields
  public _original: any;
  public is_new = false;
  public is_changed = false;

  public type = 'private';
  public projectType?: string;
  public status = 'new';
  public client_id?: string;
  public clientName?: string;
  public accountmanager_id: number;
  public accountmanagerName: string;
  public editing_by: number;
  public editorName?: string;
  public executor_id?: number;
  public reference_nr?: string;
  public own_description_activities?: string;
  public client_description_activities?: string;
  public insurance_certificate_link?: string;
  public delivery_date?: Date|string;

  public editor: any;
  public client = new Client({});
  public accountmanager = new User({});

  public accountmanagers?: string;

  public addresses: Address[] = [];
  public specialties: ProjectSpecialty[] = [];
  public activities: ProjectActivity[] = [];
  public inventories: Inventory[] = [];
  public quotations: Quotation[] = [];
  public materials: ProjectMaterial[] = [];
  public pictures: Picture[] = [];
  public events: Event[] = [];

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }
  }

  public async init() {
    // Set project type name
    switch (this.type) {
      case 'business':
        this.projectType = 'Zakelijk';
        break;
      case 'private':
        this.projectType = 'Particulier';
        break;
      default:
        this.projectType = '?';
        break;
    }

    // Set relations
    if (this.client_id) {
      this.client = await Client.query.get(this.client_id);
      this.clientName = this.client ? this.client.name : '';
    }

    if (this.accountmanager_id) {
      this.accountmanager = await User.query.get(this.accountmanager_id);
      this.accountmanagerName = this.accountmanager ? this.accountmanager.name : '';
    }

    if (this.editor && this.editor.length > 0) {
      this.editor = this.editor[0];
    }

    if (this.editing_by) {
      this.editorName = this.editor ? this.editor.name : '';
    }

    if (this.delivery_date && this.delivery_date !== 'Invalid Date') {
      this.delivery_date = new Date(this.delivery_date);
    } else {
      this.delivery_date = null;
    }
  }

  public async loadActivities() {
    if (!this.id) {
      return;
    }

    this.activities = await ProjectActivity.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const activity of this.activities) {
      await activity.init();
    }

    // Fill activities if not available yet
    if (!this.activities || this.activities.length === 0) {
      const activities = await Activity.query
        .where('project_type')
        .equals(this.type)
        .toArray();
      for (const activity of activities) {
        await activity.init();
        const projectActivity = new ProjectActivity({
          activity_id: activity.id,
          project_id: this.id
        });
        await projectActivity.init();
        this.activities.push(projectActivity);
      }
    }
  }

  public async loadMaterials() {
    if (!this.id) {
      return;
    }

    this.materials = await ProjectMaterial.query
        .where('project_id')
        .equals(this.id)
        .toArray();
    for (const material of this.materials) {
      await material.init();
    }
  }

  public async loadEvents() {
    if (!this.id) {
      return;
    }

    this.events = await Event.query
      .where('eventable_id')
      .equals(this.id)
      .and((item) => item.eventable_type == 'PAVanRooyen\\Domain\\Project\\Project').toArray();
    for (const event of this.events) {
      await event.init();
    }
  }

  public async loadSpecialties() {
    if (!this.id) {
      return;
    }

    this.specialties = await ProjectSpecialty.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const specialty of this.specialties) {
      await specialty.init();
    }

    const allSpecialties = (await Specialty.query.toArray()).filter(item => item.deleted_at === null && item.relation_group_id === this.client.relation_group_id);
    const newSpecialties = allSpecialties.filter(item => !this.specialties.find(projectSpecialty => projectSpecialty.specialty ? projectSpecialty.specialty.name === item.name : false));

    // Fill specialties if not available yet
    if (newSpecialties) {
      for (const specialty of newSpecialties) {
        await specialty.init();

        const projectSpecialty = new ProjectSpecialty({
          specialty_id: specialty.id,
          project_id: this.id
        });

        await projectSpecialty.init();

        this.specialties.push(projectSpecialty);
      }
    }
  }

  public async loadInventories() {
    this.inventories = await Inventory.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const inventory of this.inventories) {
      await inventory.init();
    }
  }

  public async loadQuotations() {
    this.quotations = await Quotation.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const quotation of this.quotations) {
      await quotation.init();
    }
  }

  public async loadAddresses() {
    this.addresses = await Address.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const address of this.addresses) {
      await address.init();
    }
  }

  public async loadPictures() {
    this.pictures = await Picture.query
      .where('project_id')
      .equals(this.id)
      .toArray();
    for (const picture of this.pictures) {
      await picture.init();
    }
  }

  /**
   * Retrieves the total price from desbribed specialty
   *
   * @param specialtyName
   * @returns number
   */
  public async getSpecialtyTotalPrice(specialtyName: string): Promise<number> {
    if (!this.specialties || this.specialties.length === 0) {
      await this.loadSpecialties();
    }

    // Find the specialty and return the price
    const specialty = this.specialties.find((specialtyItem: ProjectSpecialty) => specialtyItem.specialty ? (specialtyItem.specialty.name === specialtyName) : null);
    if (specialty && specialty.applicable) {
      // Determine whether the specialty has been toggled true, if not, don't show it and return null
      if (specialty.specialty.has_hours) {
        return (specialty.hours_estimate ? specialty.hours_estimate : 0) * specialty.specialty.cost_rate;
      } else {
        // If specialty does not contain hours, only display the initial cost rate
        return specialty.specialty.cost_rate;
      }
    } else {
      return null;
    }
  }

  public hasActivities() {
    return this.activities && this.activities.some(activity => activity.applicable);
  }

  public hasSpecialties() {
    return this.specialties && this.specialties.some(specialty => specialty.applicable);
  }

  public getApplicableSpecialties(): ProjectSpecialty[] {
    return this.specialties.filter(specialty => specialty.applicable);
  }

  public getData(): any {
    return {
      id: this.id,
      client_id: this.client_id,
      type: this.type || '',
      status: this.status || '',
      reference_nr: this.reference_nr || '',
      own_description_activities: this.own_description_activities || '',
      client_description_activities: this.client_description_activities || '',
      insurance_certificate_link: this.insurance_certificate_link || '',
      delivery_date: this.delivery_date ? moment(this.delivery_date).format('YYYY-MM-DD') : null,
      executor_id: this.executor_id,
      accountmanager_id: this.accountmanager_id,
    };
  }

  /**
   * Returns the project status list options
   */
  public static getStatusList(): SelectItem[] {
    return [
      { label: 'Nieuw', value: 'new' },
      { label: 'Geboekt', value: 'booked' },
      { label: 'In Afwachting', value: 'pending' },
      { label: 'Verloren', value: 'lost' },
      { label: 'Inactief', value: 'inactive' },
    ];
  }

  /**
   * Returns the status name by value
   * @param status string
   */
  public static getStatusName(status: string): string {
    const result = this.getStatusList().find(item => item.value === status);
    return result ? result.label : '';
  }

  /**
   * Returns the project status list options
   */
  public static getRelationList(): SelectItem[] {
    return [
      { label: 'Website', value: 'website' },
      { label: 'Erkende verhuizer', value: 'acknowledged_mover' },
      { label: 'Mondeling', value: 'verbal' },
      { label: 'Overig', value: 'other' }
    ];
  }

  /**
   * Returns the relation name by value
   * @param relation string
   */
  public static getRelationName(relation: string): string {
    const result = this.getRelationList().find(item => item.value === relation);
    return result ? result.label : '';
  }
}
