import { Pipe, PipeTransform, SecurityContext, VERSION } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Contact } from '@domain/models/contact.model';
import { SelectItem } from 'primeng/api';

@Pipe({
  name: 'ContactCompleteName'
})
export class ContactCompleteNamePipe implements PipeTransform {

  public genders: SelectItem[] = [
    { label: 'Familie', value: 'F' },
    { label: 'De heer', value: 'M' },
    { label: 'Mevrouw', value: 'V' },
  ];
  transform(contact: Contact): string {
    let result = contact.name;

    if (contact.initials) {
      result = `${contact.initials} ${contact.name}`;
    }

    return `${this.genders.find((gender) => {return gender.value == (contact.gender || 'F')}).label} ${result}`;
  }

}
