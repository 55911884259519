import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';
import { User } from '@domain/models/user.model';
import { ApiLog } from '@domain/models/api-log.model';

export class Event extends DomainModel {
  // Configuration
  public entity = 'event';
  public table = 'events';
  public schema = 'id, eventable_id, eventable_type, api_log_id, event, created_by, created_at, updated_at';
  public sync = true;
  public id: string;

  // Fields
  public eventable_id?: string;
  public eventable_type?: string;
  public api_log_id?: number;
  public created_by?: User;
  public event?: string;

  public created = new Date();
  public updated = new Date();
  public apiLog = new ApiLog({});

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }

    if (!this.created_at) {
      this.created_at = new Date();
    }

    if (!this.updated_at) {
      this.updated_at = new Date();
    }
  }

  public async init() {
    if (this.api_log_id) {
      this.apiLog = await ApiLog.query.get(this.api_log_id);
    }

    if (this.created_at) {
      this.created = new Date(this.created_at);
    }

    if (this.updated_at) {
      this.updated = new Date(this.updated_at);
    }

    if (!this.created_at) {
      this.created_at = new Date();
    }

    if (!this.updated_at) {
      this.updated_at = new Date();
    }
  }

  public getData(): any {
    if (typeof this.created_at === 'string') {
      this.created_at = new Date(this.created_at);
    }

    if (typeof  this.updated_at === 'string') {
      this.updated_at = new Date(this.updated_at);
    }

    return {
      id: this.id,
      eventable_id: this.eventable_id,
      eventable_type: this.eventable_type,
      created_by: this.created_by,
      event: this.event || '',
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  }
}
