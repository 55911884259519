import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as toastr from 'toastr';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from '@root/node_modules/rxjs';
import { DataService } from '@shared/services/data.service';
import { SynchronisationService } from '@shared/services/synchronisation.service';
import { ApiServiceWithLoaderService } from '@shared/services/api-service-with-loader.service';
import { takeUntil } from '@node_modules/rxjs/operators';
import { Activity } from '@domain/models/activity.model';

@Component({
  selector: 'app-activities-detail',
  templateUrl: './activities-detail.component.html',
  styleUrls: ['./activities-detail.component.scss']
})
export class ManageActivitiesDetailComponent implements OnInit, OnDestroy {
  public isAdd = false;
  public loading = false;
  public showErrors = false;
  public form: FormGroup;
  public mode = { isAdd: true };
  public activity: Activity = new Activity({}).getData();
  public activityTypes: SelectItem[];

  private destroy$: Subject<boolean>;

  constructor(private formBuilder: FormBuilder,
              private dataService: DataService,
              private api: ApiServiceWithLoaderService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private synchronisationService: SynchronisationService,
              private confirmationService: ConfirmationService,
              private route: ActivatedRoute) {
    this.form = new FormGroup({});
    this.activityTypes = [
      {
        label: 'Particulier',
        value: 'private',
      },
      {
        label: 'Zakelijk',
        value: 'business',
      },
    ];
    this.isAdd = false;
    this.loading = true;
    this.showErrors = false;
    this.destroy$ = new Subject();

    // Get id of url to edit by route params
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      const id = params['id'];
      this.isAdd = id === 'add';

      if (!this.isAdd) {
        this.api.get('/activities/' + id).pipe(takeUntil(this.destroy$)).subscribe((result: any) => {
          if (result && result.data) {
            this.activity = result.data;
            this.activity.project_type_label = result.data.type === 'private' ? 'Particulier' : 'Zakelijk';
          }

          this.initForm();
          this.loading = false;
        });
      } else {
        this.initForm();
        this.loading = false;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    this.initForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      project_type: [this.activity.project_type || 'private' , [Validators.required]],
      name: [this.activity.name || '' , [Validators.required]],
      sends_notification: [this.activity.sends_notification || false],
    });
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.showErrors = false;
      this.loading = true;

      const itemData = this.form.getRawValue();

      if (itemData.sends_notification instanceof Array) {
        itemData.sends_notification = itemData.sends_notification[0] !== 0;
      }

      let request;
      if (this.isAdd) {
        request = this.api.post('/activities', itemData);
      } else {
        request = this.api.patch('/activities/' + this.route.snapshot.params['id'], itemData);
      }

      request.subscribe(_ => {
        toastr.success('Activiteit succesvol opgeslagen', 'Activiteiten');
        this.router.navigateByUrl('/').then(() => {
          // HACK Use double navigation to force reload..
          this.router.navigateByUrl('/admin/manage/activities');
        });
      }, (error: any) => {
        if (error.status === 422 && error.json) {
          toastr.warning('Ongeldige invoer', 'Activiteiten');
        } else {
          toastr.error('Fout bij opslaan Activiteit', 'Activiteiten');
        }

        this.loading = false;
      });
    } else {
      this.showErrors = true;
    }
  }

  public onCancel(): void {
    this.router.navigateByUrl('/admin/manage/activities');
  }
}
