import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  production: false,
  name: 'Acceptatie',
  applicationName: 'AA Verhuizers - Inventarisatie',
  baseUrl: 'https://aaverhuizers.inventorycomplete.acceptatie.emendis.nl/api',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  features: {
    arent_materials: false
  },
  db_name: 'aaverhuizers_db',
  clientName: 'aaverhuizers'
};
