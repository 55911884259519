import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { OfflineTableOptions, OfflineTableComponent } from '@shared/controls/table/offline-table.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import { ProjectService } from '@shared/services/project.service';
import { Project } from '@domain/models/project.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-inventory-contact-list',
  templateUrl: 'contact-list.component.html',
})
export class InventoryContactListComponent implements OnInit, OnDestroy {
  @ViewChild(OfflineTableComponent) table: OfflineTableComponent;

  public tableOptions: OfflineTableOptions;
  public project = new Project({});
  public disabled: boolean = false;

  private subscriptionProjectLoaded: Subscription;
  private subscriptionContactsAdded: Subscription;

  public constructor(private route: ActivatedRoute,
                     private router: Router,
                     private confirmationService: ConfirmationService,
                     private projectService: ProjectService) {
    this.projectService.projectIsReadOnly.subscribe((readOnly: boolean) => {
      this.disabled = readOnly;
    });
  }

  public ngOnInit(): void {
    this.project = this.projectService.getProject();
    this.setTableOptions();

    // In case project loaded
    this.subscriptionProjectLoaded = this.projectService.projectLoaded.subscribe((project: Project) => {
      this.project = project;
      this.setTableOptions();
      if (this.table) {
        this.table.onChangeTable();
      }
      this.projectService.setCurrentClient(this.project.client);
    });

    // In case contact was added (from popup)
    this.subscriptionContactsAdded = this.projectService.contactsAdded.subscribe(_ => {
      if (this.table) {
        this.table.onChangeTable();
      }
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionProjectLoaded) {
      this.subscriptionProjectLoaded.unsubscribe();
    }
    if (this.subscriptionContactsAdded) {
      this.subscriptionContactsAdded.unsubscribe();
    }
  }

  public setTableOptions(): void {
    this.tableOptions = new OfflineTableOptions({
      columns: [
        { title: 'Naam', name: 'name' },
        { title: 'E-mail', name: 'email' },
        { title: 'Telefoon', name: 'phone' },
        { title: 'Mobiel', name: 'mobile_phone' }
      ],
      paging: false,
      search: false,
      noResultsMessage: 'Er zijn nog geen contactpersonen aangemaakt',
      withDelete: true,
      itemsPerPage: 500,
      url: '/contacts',
      tableName: 'contacts',
      filtering: { hiddenColumns: [{ name: 'client_id', filter: this.project.client_id, filterMode: 'equals' }] },
    });
  }

  public onRowClick(data: any): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact(popup:admin/project/contact/' + data.id + ')');
    }
  }

  public onAddClick(): void {
    if (!this.disabled) {
      this.router.navigateByUrl('/admin/project/' + this.project.id + '/contact(popup:admin/project/contact/add)');
    }
  }

  public onDeleteClick(): void {
    if (!this.disabled) {
      this.confirmationService.confirm({
        message: 'Wilt u de geselecteerde contacten verwijderen?',
        header: 'Bevestiging',
        icon: 'fa fa-question-circle',
        accept: _ => {
          this.table.selectedRows.forEach((row: any) => {
            this.projectService.deleteContact(row.id);
          });

          this.table.onChangeTable();
        }
      });
    }
  }
}
