import { DomainModel } from '@domain/domain.model';
import * as uuid from 'uuid/v4';
import { User } from '@domain/models/user.model';

export class ApiLog extends DomainModel {
  // Configuration
  public entity = 'api-log';
  public table = 'api_logs';
  public schema = 'id, project_id, api, status, message, remote_id, data, created_at, updated_at';
  public sync = true;
  public id: string;

  // Fields
  public project_id?: string;
  public api?: string;
  public status?: string;
  public message?: string;
  public remote_id?: string;
  public data?: string;

  public createdBy = new User({});

  // Constructor
  constructor(attributes) {
    super(attributes);

    if (!attributes.id) {
      this.id = uuid();
    }

    if (!this.created_at) {
      this.created_at = new Date();
    }

    if (!this.updated_at) {
      this.updated_at = new Date();
    }
  }

  public async init() {
    if (!this.created_at) {
      this.created_at = new Date();
    }

    if (!this.updated_at) {
      this.updated_at = new Date();
    }
  }

  public getData(): any {
    if (typeof this.created_at === 'string') {
      this.created_at = new Date(this.created_at);
    }

    if (typeof  this.updated_at === 'string') {
      this.updated_at = new Date(this.updated_at);
    }

    return {
      id: this.id,
      project_id: this.project_id,
      api: this.api,
      status: this.status,
      message: this.message || '',
      remote_id: this.remote_id,
      data: this.data,
      created_at: this.created_at,
      updated_at: this.updated_at,
    };
  }
}
